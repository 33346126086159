import React from 'react';
import logoWhite from '../../../assets/images/logo-white.png';
import tagLine from '../../../assets/images/tagline.png';
import { build } from '../../../utils/Constants';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import history from '../../../utils/History';
const NotFound = ({ height }) => {
  const path = window.location.pathname;
  const url = window.location.href;
  const functionToCheckOverlapPath = (path) => {
    if (path.includes('/booking-response/')) {
      return true;
    }
    if (path.includes('/update-password')) {
      return true;
    }
    if (path.includes('/rateEnquiryResponseForm')) {
      return true;
    }
    switch (path) {
      case '/':
        return true;
      case '/dashboard':
        return true;
      case '/dashboard':
        return true;
      case '/login':
        return true;
      case '/':
        return true;
      case '':
        return true;
      case '/login':
        return true;
      case '/thankYouPage':
        return true;
      case '/thankYouPage':
        return true;
      case '/customerKyc':
        return true;
      case '/customerKyc':
        return true;
      case '/customerKyc':
        return true;
      case '/forgotPassword':
        return true;
      case '/forgotPassword':
        return true;
      case '/dashboard/changePassword':
        return true;
      case '/dashboard/changePassword':
        return true;
      case '/change-password':
        return true;
      case '/change-password':
        return true;
      case '/tandc':
        return true;
      case '/super-user-dashboard':
        return true;
      case '/super-user-dashboard':
        return true;
      case '/tandc':
        return true;
      default:
        return false;
    }
  };
  return (
    <React.Fragment>
      <button
        onClick={() => {
          history.push(url.includes('http://103.147.226.35/') ? '/dashboard' : '/dashboard');
          window.location.reload();
        }}
        className="notFoundBackBtn"
        style={{ display: functionToCheckOverlapPath(path) ? 'none' : '' }}
      >
        {' '}
        {!height && <FontAwesomeIcon className="mr-3" icon={faArrowLeft} />}
        {height ? 'Go To Dashboard' : 'Go Back'}
      </button>
      <div
        className="landingNotFound"
        style={{
          display: functionToCheckOverlapPath(path) ? 'none' : '',
          height: height ? height : '100vh'
        }}
      >
        <div className="logisticText">
          <img src={build + tagLine} className="tagline" alt="img" />
        </div>
        <a href="https://allimpexo.com">
          <div className="login-form">
            <img src={build + logoWhite} className="impexo-white" alt="img" />
            {/* <span
            style={{
              fontSize: '21px',
              fontFamily: 'Raleway',
              color: 'white',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            404 Page Not Found <br />
          </span> */}
            <span
              style={{
                fontSize: '21px',
                fontFamily: 'Raleway',
                color: 'white',
                alignItems: 'center',
                textAlign: 'center'
              }}
            >
              We couldn't find what you were looking for. Double-check the URL or try browsing other
              sections of the site.
              <br />
            </span>

            <br />

            <span
              className="pull-right"
              style={{
                fontSize: '20px',
                fontFamily: 'Raleway',
                color: 'white'
              }}
            >
              {' '}
              -Impexo Team
            </span>
          </div>
        </a>
      </div>
    </React.Fragment>
  );
};

export default NotFound;
